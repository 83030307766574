import React from 'react';
import style from './RequiredInfoIns.module.css';

export default function RequiredInfo() {
    return(
        <div className={style.container + ' margin-top-md'}>
            <div className={style['sub-container'] + ' container-margin'}>
                <div className={style['container-left']}>
                    <iframe height="315" src="https://www.youtube.com/embed/NNZ74MCZ2ho"
                    title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
                    </iframe>
                </div>
                <div className={style['container-right']}>
                    <div className={style.title}>
                    Agenda de las clases en vivo del próximo programa <br/> Del 31 de Octubre al 28 de Noviembre 2024 <br/>
                    </div>
                    <br />
                    <div className={style.text}>
                        <p>
                            <b>¿Cuándo?</b> Todos los jueves <br />
                            <b>Horario:</b> 18:00 a 19:30 hrs. (hora central CDMX) <br />
                            <b>¿Dónde?</b> En vivo online desde tu perfil de Súbete a la Nube.
                            <br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};